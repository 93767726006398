<template lang="pug">
div
  van-row(type="flex" justify="center")
    van-col
      h3 {{$store.state.companyInfo.com_name}}
  van-form
    van-field(v-model='mobile', clearable name='手机号', label='手机号', placeholder='手机号', :rules="[{ required: true, message: '请填写手机号' }]")
    van-field(v-model='code', clearable name='验证码', label='验证码', placeholder='验证码', :rules="[{ required: true, message: '请填写验证码' }]")
      template(#button)
        van-button(size='small', type='default' @click="sendCode") 发送验证码
    van-field(v-model='newpass' clearable type='password' name='新密码', label='新密码', placeholder='新密码', :rules="[{ required: true, message: '请填写新密码' }]")
    van-field(v-model='renewpass' clearable type='password' name='确认新密码', label='确认新密码', placeholder='确认新密码', :rules="[{ required: true, message: '请填写确认新密码' }]")
    van-row.row
      van-button.col(type='info' native-type='submit' block  round @click='onSubmit' :disabled='commitState') 找回密码
</template>

<script>
let startTime = 60

export default {
  data() {
    return {
      commitState: false,
      mobile: '',
      code: '',
      newpass: '',
      renewpass: '',
      verifyState: false,
      verify: '获取动态密码'
    }
  },
  methods: {
    async onSubmit() {
      if (!this.mobile) {
        this.$toast('手机号不能为空')
      } else if (!this.code) {
        this.$toast('请输入验证码')
      } else if(this.newpass != this.renewpass){
        this.$toast('两次密码输入不一致')
      } else if([...this.renewpass].length < 6) {
        this.$toast('密码长度不能少于6位')
      } else {
        try {
          this.commitState = true
          let param = {mobile:this.mobile,code:this.code,password:this.newpass}
          let res = await this.$api.ResetPasd(param)
          this.$toast('找回成功')
        } catch (error) {
          this.$toast(error.response.data.message)
        }
      }
      this.commitState = false
    },
    countdowntime() {
      let time = setTimeout(this.countdowntime, 1000)
      if (startTime === 0) {
        clearTimeout(time)
        this.verify = '获取动态密码'
        startTime = 60
        this.verifyState = false
        return false
      } else {
        startTime--
      }
      this.verify = startTime + 's后重新获取'
    },
    async sendCode() {
      if (!this.verifyState) {
        try {
          let params = {
            type: 'web',
            mobile: this.mobile
          }
          await this.$api.RequestSmsCode(params)
          this.verifyState = true
          this.countdowntime()
          this.$toast('验证码发送成功!')
        } catch (e) {
          this.$toast(e.response.data.message)
        }
      }
    }
  },
  async mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.row {
  margin: 0px 10px;
}
.col {
  margin: 10px 0px;
}
</style>  